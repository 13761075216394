<!--
File: Report_1_AdministrativeSetup.vue
Description: produce the list of DEUs, and Regions under RMD for printing.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown :label="$t('label.select_region')" v-model="selected_region" @input='reloadData' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-datepicker v-model="selectedDate" md-immediately :md-open-on-focus="false">
            <label>{{ $t('label.select_date') }}</label>
          </md-datepicker>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="print" :disabled="btnDisabled"> {{ $t('buttons.print') }}
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner" class="md-progress-spinner" :md-diameter="70" md-mode="indeterminate" />
      <md-table class='"paginated-table table-striped table-hover' :value="report" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell style="width:20%" :md-label="$t('road_network.region')" md-sort-by="region_description">
            {{ item.region_description }}
          </md-table-cell>
          <md-table-cell style="width:10%" :md-label="$t('road_network.dep')" md-sort-by="deu_description">
            <a href='#' @click.stop.prevent='showSections(item)'>
              {{ deuLabel + '-' + item.deu_description }}
            </a>
          </md-table-cell>
          <md-table-cell style="width:20%" :md-label="$t('road_network.length_km_road_class_em')"
            md-sort-by="length_km_road_class_em" md-numeric>
            {{ item.length_km_road_class_em | numFormat }}
          </md-table-cell>
          <md-table-cell style="width:20%" :md-label="$t('road_network.length_km_road_class_m')"
            md-sort-by="length_km_road_class_m" md-numeric>
            {{ item.length_km_road_class_m | numFormat }}
          </md-table-cell>
          <md-table-cell style="width:15%" :md-label="$t('road_network.length_km_road_class_zh')"
            md-sort-by="length_km_road_class_zh" md-numeric>
            {{ item.length_km_road_class_zh | numFormat }}
          </md-table-cell>
          <!--md-table-cell style="width:15%" :md-label="$t('road_network.road_class_none')" md-sort-by="road_class_none"
            md-numeric>
            {{ item.road_class_none | numFormat }}
          </md-table-cell-->
        </md-table-row>
      </md-table>
      <md-table id="printFooter">
        <md-table-row>
          <md-table-head style="width:20%">{{ $t('label.total') }}</md-table-head>
          <md-table-head style="width:10%">{{ total_deu }}</md-table-head>
          <md-table-head style="width:20%" md-numeric>{{ total_length_km_road_class_em }}</md-table-head>
          <md-table-head style="width:20%" md-numeric>{{ total_length_km_road_class_m }}</md-table-head>
          <md-table-head style="width:15%" md-numeric>{{ total_length_km_road_class_zh }}</md-table-head>
          <!--md-table-head style="width:15%" md-numeric>{{ total_road_class_none }}</md-table-head-->
        </md-table-row>
      </md-table>
    </md-card-content>
  </md-card>
</template>
<script>
import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
import { numFormat } from "@/store/refdata/format_helpers"
import { customSortMixin } from '@/mixins/customSortMixin'
import printReport from '@/mixins/printMixin'

export default {
  name: 'roads-administrative-setup',
  mixins: [customSortMixin, printReport],
  data() {
    return {
      selected_region: null,
      selectedDate: new Date(),
      btnDisabled: true,
      deuLabel: '',

      showSpinner: false,
      currentSort: 'region_description',
      currentSortOrder: 'asc'
    }
  },
  components: {
    RegionsDropdown
  },
  mounted() {
    this.showSpinner = true
    this.deuLabel = this.$t('road_network.dep')
  },

  methods: {
    reloadData() {
      this.showSpinner = true
      this.btnDisabled = true
      this.$store.dispatch('REPORT_1_ADMINISTRATIVE_SETUP', {
        inserted_date: this.selectedDate.toISOString().substring(0, 10),
        region_id: this.selected_region
      }).then(() => {
        this.btnDisabled = false
        this.showSpinner = false
      })
    },

    print() {
      this.showSpinner = true
      const tableHeaders = `<tr>
          <th>${this.$t('road_network.region')}</th>
          <th>${this.$t('road_network.dep')}</th>
          <th>${this.$t('road_network.length_km_road_class_em')}</th>
          <th>${this.$t('road_network.length_km_road_class_m')}</th>
          <th>${this.$t('road_network.length_km_road_class_zh')}</th>
        </tr>`

      // Генерация строк таблицы
      const tableRowsArray = this.report.map(item => {
        return `<tr>
          <td>${item.region_description}</td>
          <td>${this.deuLabel}-${item.deu_description}</td>
          <td class='numCell'>${numFormat(item.length_km_road_class_em)}</td>
          <td class='numCell'>${numFormat(item.length_km_road_class_m)}</td>
          <td class='numCell'>${numFormat(item.length_km_road_class_zh)}</td>
        </tr>`;
      });

      // Добавляем итоговую строку в массив строк
      tableRowsArray.push(`<tr>
        <th>${this.$t('label.total')}</th>
        <th>${this.total_deu}</th>
        <th class='numCell'>${this.total_length_km_road_class_em}</th>
        <th class='numCell'>${this.total_length_km_road_class_m}</th>
        <th class='numCell'>${this.total_length_km_road_class_zh}</th>
      </tr>`
      );

      // Преобразуем массив строк таблицы в строку
      const tableRows = tableRowsArray.join('');

      const prtHtml = `<h4 style='text-align:center'>${this.$t('label.reports_title')}</h4>
        <h4 style='text-align:center'>${this.$t('label.report1_title')}
        &nbsp; ${this.$t('label.as_on')} ${this.selectedDate.toLocaleDateString()}</h4>`;

      this.printReport(prtHtml, tableHeaders, tableRows)
      this.showSpinner = false
    },

    showSections(item) {
      const hist = {
        selected_region: item.region_id,
        selected_deu: item.deu_id,
        perPage: 20,
        currentPage: 1
      }
      this.$store.dispatch('SAVE_HISTORY', { data: hist, for: 'sections' })
      this.$store.dispatch('SET_USE_HISTORY')
      this.$router.push('/inventory_data/sections')
    },
  },

  computed: {
    report() {
      return this.customSort(this.$store.state.Reports.report_1_data, 'deu_description')
    },
    total_deu() {
      return this.report.length
    },
    total_length_km_road_class_m() {
      return numFormat(this.report.reduce((acc, currVal) => acc + currVal.length_km_road_class_m, 0,))
    },
    total_length_km_road_class_em() {
      return numFormat(this.report.reduce((acc, currVal) => acc + currVal.length_km_road_class_em, 0,))
    },
    total_length_km_road_class_zh() {
      return numFormat(this.report.reduce((acc, currVal) => acc + currVal.length_km_road_class_zh, 0,))
    },
    total_road_class_none() {
      return numFormat(this.report.reduce((acc, currVal) => acc + currVal.road_class_none, 0,))
    },
  },

  watch: {
    selectedDate(value) {
      if (!value) this.selectedDate = new Date()
      else this.reloadData()
    },
  }
}
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.md-table-cell.md-numeric {
  padding: 0px !important;
  padding-right: 18px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}

thead {
  display: table-header-group;
}

tfoot {
  display: table-footer-group;
}
</style>